import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { FormPreset } from '../../../../../../constants/form-types'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'

export const EMAIL_REGEX = '^.+@.+\\.[a-zA-Z]{2,63}$'

export class Email extends GeneralText {
  public get customFields() {
    if (this.isContactsListTabExperiment()) {
      return this.base.customFields
    }
    return []
  }

  public get mainCrmTypes() {
    return [CRM_TYPES.EMAIL]
  }

  protected get icon() {
    return iconNames.email
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.EMAIL
  }

  public get crmType() {
    return CRM_TYPES.EMAIL
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data(): any {
    return {
      textType: 'email',
      pattern: EMAIL_REGEX,
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.recommended }
      },
      [FormPreset.GET_SUBSCRIBERS]: {
        required: FieldRenderConfigType.DISABLED,
        isMandatory: true,
      },
    }
  }

  protected get props(): any {
    return {
      required: true,
    }
  }
}
