import * as _ from 'lodash'
import { undoable, withBi } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { FormField, PROPERTIES } from '../../../constants/api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_TITLE } from '../../../constants/roles'
import {
  DEFAULT_FIELD_MARGIN,
  FORM_PADDING,
  PADDING_FROM_TITLE,
  TOP_PADDING,
  FieldsLayout,
} from './constants/layout-settings'
import { EXTRA_COLSPANS } from './constants/columns'
import CoreApi from '../core-api'
import { getFieldsLayout } from './constants/layout-settings'
import { shiftListItems } from '../../../utils/utils'
import {
  FieldPropHandler,
  getTextAlignmentProp,
  getLabelMarginProp,
  getLabelPaddingProp,
  getInputTextPaddingProp,
} from './utils'
import { ResponsiveLayout, Connection } from '@wix/platform-editor-sdk'
import { isNavigationButton } from '../steps/utils'
import { SPACE_BETWEEN_FIELDS } from '../fields/api'
import Experiments from '@wix/wix-experiments'
import { getPrimaryConnection } from '../utils'
import { fieldsStore } from '../preset/fields/fields-store'

export default class LayoutApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: Experiments

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  public updateFieldLayout(fieldRef: ComponentRef, newLayout) {
    return this.boundEditorSDK.components.layout.update({
      componentRef: fieldRef,
      layout: newLayout,
    })
  }

  @undoable()
  public async updateFieldsTextAlignment(
    componentRef: ComponentRef,
    fields: FormField[],
    alignment
  ) {
    await this._updateFieldsProp(fields, getTextAlignmentProp, alignment)
    return this.coreApi.setComponentConnection(componentRef, {
      textAlignment: alignment,
    })
  }

  @undoable()
  public async updateFieldsLabelMargin(componentRef: ComponentRef, fields: FormField[], margin) {
    await this._updateFieldsProp(fields, getLabelMarginProp, margin)
    return this.coreApi.setComponentConnection(componentRef, { labelMargin: margin })
  }

  @undoable()
  public async updateFieldsLabelPadding(componentRef: ComponentRef, fields: FormField[], value) {
    await this._updateFieldsProp(fields, getLabelPaddingProp, value)
    return this.coreApi.setComponentConnection(componentRef, { labelPadding: value })
  }

  @undoable()
  public async updateFieldsInputTextPadding(
    componentRef: ComponentRef,
    fields: FormField[],
    value
  ) {
    await this._updateFieldsProp(fields, getInputTextPaddingProp, value)
    return this.coreApi.setComponentConnection(componentRef, { textPadding: value })
  }

  private async _updateFieldsProp(
    fields: FormField[],
    getPropHandler: FieldPropHandler,
    value: PROPERTIES
  ) {
    const fieldsUpdates = []

    fields.map(field => {
      const { componentRef, fieldType } = field
      const fieldUpdateProp = getPropHandler(fieldType, value)

      if (!fieldUpdateProp) return

      fieldsUpdates.push(
        this.boundEditorSDK.components.properties.update({
          componentRef: componentRef,
          props: fieldUpdateProp,
        })
      )
    })

    return Promise.all(fieldsUpdates)
  }

  public async showFieldsTitle(componentRef: ComponentRef, fields: FormField[]) {
    const fieldsUpdates = []

    fields.map(field => {
      if (!fieldsStore.allFieldsData[field.fieldType].supportsLabel) {
        return
      }

      fieldsUpdates.push(
        this.boundEditorSDK.components.data.update({
          componentRef: field.componentRef,
          data: { label: field.label },
        })
      )
    })

    const updateConnection = this.coreApi.setComponentConnection(componentRef, {
      showFieldsTitle: true,
    })

    return Promise.all([...fieldsUpdates, updateConnection])
  }

  public async hideFieldsTitle(componentRef: ComponentRef, fields: FormField[]) {
    const fieldsUpdates = []

    fields.map(field => {
      if (!fieldsStore.allFieldsData[field.fieldType].supportsLabel) {
        return
      }

      fieldsUpdates.push(
        this.boundEditorSDK.components.data.update({
          componentRef: field.componentRef,
          data: { label: '' },
        })
      )
    })

    const updateConnection = this.coreApi.setComponentConnection(componentRef, {
      showFieldsTitle: false,
    })

    return Promise.all([...fieldsUpdates, updateConnection])
  }

  private async _getButtonAndMessageLayouts(formRef: ComponentRef) {
    const layouts = await this.getChildrenLayouts(formRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE])
    return {
      submitButtonLayout: layouts.find(field => (<FormField>field).role === ROLE_SUBMIT_BUTTON),
      messageLayout: layouts.find(field => (<FormField>field).role === ROLE_MESSAGE),
    }
  }

  @withBi({ startEvid: EVENTS.PANELS.adiEditFormPanel.DRAG_FIELD_COMPLETE })
  public reorderFormFieldsADI(
    componentRef: ComponentRef,
    { fieldsIndex = null, showTitles = false } = {},
    _biData = {}
  ) {
    return this._updateFieldsLayout(componentRef, 1, {
      isAdiLayout: true,
      fieldsIndex,
      showTitles,
    })
  }

  public updateFieldsLayoutADI(
    componentRef: ComponentRef,
    {
      fieldsIndex = null,
      showTitles = false,
      inputFields = null,
      messageAndButtonLayout = null,
    } = {}
  ) {
    return this._updateFieldsLayout(componentRef, 1, {
      isAdiLayout: true,
      fieldsIndex,
      showTitles,
      inputFields,
      messageAndButtonLayout,
    })
  }

  private _getCruicalElementHeight(elementLayout, padding) {
    if (!elementLayout) return 0
    return elementLayout.height + padding
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.formLayoutPanel.CHANGE_LAYOUT,
    endEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED,
  })
  public updateFieldsLayout(componentRef: ComponentRef, columnsNumber: number, _biData = {}) {
    this._updateFieldsLayout(componentRef, columnsNumber, {})
  }

  @undoable()
  public async updateFieldsLayoutByCustomSpacing({
    componentRef,
    formLayout,
    rowSpacing,
    colSpacing,
    showFieldsTitle,
  }) {
    await this._updateFieldsLayout(componentRef, formLayout, {
      rowSpacing,
      colSpacing,
      showTitles: showFieldsTitle,
    })
    return this.coreApi.setComponentConnection(componentRef, {
      spaceBetweenRows: rowSpacing,
      spaceBetweenCols: colSpacing,
    })
  }

  private async _updateFieldsLayout(
    componentRef: ComponentRef,
    columnsNumber: number,
    {
      isAdiLayout = false,
      fieldsIndex = null,
      showTitles = false,
      rowSpacing = 0,
      colSpacing = null,
      inputFields = null,
      messageAndButtonLayout = null,
    } = {}
  ): Promise<void> {
    let fields =
      inputFields ||
      (await this.coreApi.fields.getFieldsSortByXY(componentRef))

    if (fieldsIndex) {
      const { removedIndex, addedIndex } = fieldsIndex
      fields = shiftListItems(_.cloneDeep(fields), removedIndex, addedIndex)
    }
    const fieldsLayout = getFieldsLayout(isAdiLayout, showTitles, rowSpacing, colSpacing)

    const titleHeight = await this.updateComponentByTitle(componentRef)
    const layoutCalc = await this._calcUpdateFields(componentRef, fields, {
      columnsNumber,
      titleHeight,
      fieldsLayout,
      colSpacing,
    })
    const { height: oldHeight } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })
    const { submitButtonLayout, messageLayout } =
      messageAndButtonLayout || (await this._getButtonAndMessageLayouts(componentRef))

    const newHeight =
      layoutCalc.y +
      layoutCalc.maxHeight +
      fieldsLayout.lastFieldPadding +
      this._getCruicalElementHeight(submitButtonLayout, fieldsLayout.rolePadding.SUBMIT) +
      this._getCruicalElementHeight(messageLayout, fieldsLayout.rolePadding.MESSAGE)

    if (oldHeight < newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }

    await Promise.all([
      ...layoutCalc.updates.map(({ componentRef, layout }) =>
        this.boundEditorSDK.components.layout.update({
          componentRef,
          layout,
        })
      ),
      ...this._updateSubmitAndMessage(submitButtonLayout, messageLayout, layoutCalc, fieldsLayout),
    ])

    if (oldHeight > newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }

    await this.coreApi.setComponentConnection(componentRef, {
      columns: columnsNumber,
    })
  }

  public async updateComponentByTitle(componentRef: ComponentRef): Promise<number> {
    const titleComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_TITLE)
    if (!titleComponentRef) {
      return 0
    }

    const { height } = await this.boundEditorSDK.components.layout.get({
      componentRef: titleComponentRef,
    })
    await this.boundEditorSDK.components.layout.update({
      componentRef: titleComponentRef,
      layout: { x: FORM_PADDING, y: TOP_PADDING },
    })

    return height
  }

  public async getChildrenLayouts(
    componentRef: ComponentRef,
    childRoles: string[] | string,
    withoutFilter = false
  ): Promise<any[]> {
    if (_.isString(childRoles)) {
      childRoles = [<string>childRoles]
    }
    const pred = role => _.includes(childRoles, role)

    const childComps = await this.boundEditorSDK.components.getChildren({
      componentRef,
    })
    const childrenLayouts = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.coreApi.getComponentConnection(child)
        if (withoutFilter || pred(role)) {
          const layout = await this.boundEditorSDK.components.layout.get({
            componentRef: child,
          })
          return { componentRef: child, role, ...layout }
        }
        return null
      })
    )
    return _.filter(childrenLayouts, x => !!x)
  }

  private _updateSubmitAndMessage(submitLayout, messageLayout, { y, maxHeight }, FIELDS_LAYOUT) {
    let submitPadding = 0
    const updates = []
    if (submitLayout) {
      submitPadding = submitLayout.height + FIELDS_LAYOUT.rolePadding.SUBMIT
      updates.push(
        this.boundEditorSDK.components.layout.update({
          componentRef: submitLayout.componentRef,
          layout: { y: y + maxHeight + FIELDS_LAYOUT.rolePadding.SUBMIT },
        })
      )
    }
    if (messageLayout) {
      updates.push(
        this.boundEditorSDK.components.layout.update({
          componentRef: messageLayout.componentRef,
          layout: {
            y: y + maxHeight + FIELDS_LAYOUT.rolePadding.MESSAGE + submitPadding,
          },
        })
      )
    }
    return updates
  }

  public updateYWithPadding(componentRef: ComponentRef, padding, { y, maxHeight }, key = 'y') {
    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { [key]: y + maxHeight + padding },
    })
  }

  public async centerComponentInsideLightbox(componentRef: ComponentRef) {
    const { width, height, x, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })

    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: {
        x: _.max([0, x - width / 2]),
        y: _.max([0, y - height / 2]),
      },
    })
  }

  private _calcUpdateFields(
    componentRef: ComponentRef,
    fields,
    {
      columnsNumber,
      titleHeight = 0,
      fieldsLayout,
      colSpacing,
    }: {
      columnsNumber: number
      titleHeight: number
      fieldsLayout: FieldsLayout
      colSpacing?: number
    }
  ) {
    const fieldMargin = colSpacing !== null ? colSpacing : DEFAULT_FIELD_MARGIN
    const reduceFunc = async (prevFieldLayout, field) => {
      const { updates, startX, x, y, maxHeight, defaultWidth, currentCol } = await prevFieldLayout
      const colSize = await this._getFieldColSize(field.componentRef, columnsNumber)
      const width = colSize * defaultWidth + (colSize - 1) * fieldMargin
      let nextCol = currentCol + colSize
      let layout = { width, x: x + width + fieldMargin, y }
      let overrideMaxHeight = -1
      if (nextCol > columnsNumber) {
        nextCol = colSize
        layout = { width, x: startX, y: y + maxHeight + fieldsLayout.heightPadding }
        overrideMaxHeight = 0
      }

      return {
        updates: [
          ...updates,
          {
            componentRef: field.componentRef,
            layout,
          },
        ],
        startX,
        x: layout.x,
        y: layout.y,
        width,
        defaultWidth,
        maxHeight: _.max([overrideMaxHeight > -1 ? overrideMaxHeight : maxHeight, field.height]),
        currentCol: nextCol,
      }
    }

    const getBoxConfig = async (
      componentRef: ComponentRef,
      columns: number,
      titleHeight: number
    ) => {
      const { width } = await this.boundEditorSDK.components.layout.get({
        componentRef,
      })
      const titleExtraHeight = titleHeight > 0 ? titleHeight + PADDING_FROM_TITLE : 0

      return {
        updates: [],
        startX: fieldsLayout.startX,
        currentCol: columns,
        x: fieldsLayout.formPadding,
        y: 0,
        width: 0,
        maxHeight: fieldsLayout.formPadding + titleExtraHeight - DEFAULT_FIELD_MARGIN / 2,
        defaultWidth: fieldsLayout.defaultWidth(width, columns),
        firstFieldPerXAxis: {},
      }
    }

    return _.reduce(fields, reduceFunc, getBoxConfig(componentRef, columnsNumber, titleHeight))
  }

  private async _getFieldColSize(componentRef: ComponentRef, columns: number): Promise<number> {
    const type = await this.boundEditorSDK.components.getType({ componentRef })
    return _.min([EXTRA_COLSPANS[type] || 0, columns - 1]) + 1
  }

  public async getStackChildrenResponsiveLayouts(
    stackRef: ComponentRef
  ): Promise<
    { layoutResponsive: ResponsiveLayout; componentRef: ComponentRef; role: string | undefined }[]
  > {
    const children = await this.boundEditorSDK.components.getChildren({ componentRef: stackRef })
    const convertLayouts = (item: {
      componentRef: ComponentRef
      layoutResponsive: ResponsiveLayout
      connections: Connection[]
    }) => ({
      componentRef: item.componentRef,
      layoutResponsive: item.layoutResponsive,
      role: _.get(getPrimaryConnection(item.connections), 'role'),
    })
    const layouts = await this.boundEditorSDK.components.get({
      componentRefs: children,
      properties: ['layoutResponsive', 'connections'],
    })
    return _.sortBy(layouts.map(convertLayouts), 'layoutResponsive.itemLayouts[0].order')
  }

  private async _calcYAxisLimit(componentRef: ComponentRef, fieldY: number, fieldsData?: FormField[]): Promise<number> {
    const childLayouts = fieldsData ? fieldsData : await this.getChildrenLayouts(componentRef, null, true)
    const navigationButtons = _.filter(
      childLayouts,
      element => isNavigationButton(element.role) && fieldY < element.y
    )
    if (!_.isEmpty(navigationButtons)) {
      return _.get(
        _.minBy(navigationButtons, (field: any) => field.y),
        'y'
      )
    } else {
      const { height, y } = await this.boundEditorSDK.components.layout.get({
        componentRef,
      })
      return height + y - SPACE_BETWEEN_FIELDS
    }
  }

  public async addHeightToContainerIfFieldCrossedLimit(
    componentRef: ComponentRef,
    totalHeightToAdd: number,
    newFieldY: number,
    fieldsData?: FormField[]
  ) {
    if (!this.experiments.enabled('specs.crm.FormBuilderAddFieldContainerHeightNewBehaviour')) {
      await this.coreApi.addHeightToContainers(componentRef, totalHeightToAdd)
      return true
    }

    const yAxisLimit = await this._calcYAxisLimit(componentRef, newFieldY, fieldsData)
    const shouldAddHeightToContainer = totalHeightToAdd + newFieldY >= yAxisLimit

    if (shouldAddHeightToContainer) {
      await this.coreApi.addHeightToContainers(componentRef, totalHeightToAdd)
    }

    return shouldAddHeightToContainer
  }
}
