import * as _ from 'lodash'
import translations from '../../../utils/translations'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { ControllerType } from '../../../constants/api-types'
import { GET_SUBSCRIBERS_HELP_ID } from './manifest-commons'

export const createFirstTimeExperienceManifest = (
  { plugins }: AppStateObject = {},
  controllerType: ControllerType
) => {
  const { t } = translations
  const isMultiStepForm =
    _.includes(plugins, FormPlugin.MULTI_STEP_FORM) ||
    controllerType === ControllerType.MULTI_STEP_FORM

  const isGetSubscribersForm =
    _.includes(plugins, FormPlugin.GET_SUBSCRIBERS) ||
    controllerType === ControllerType.GET_SUBSCRIBERS

  if (isMultiStepForm)
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.multiStep.title'),
        content: t('firstTimePanel.multiStep.subtitle'),
        headerBg:
          //TODO: Don't use hard-coded app version, find a way to dynamically fetch version (on mockRC's as well)
          'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/multistep-first-time.gif',
        headerBgRetina:
          'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/multistep-first-time-retine.gif',
        bgColor: '#25C0C7',
      },
    }
  if (isGetSubscribersForm)
    return {
      firstTimeExperience: {
        contentTitle: t('firstTimePanel.getSubscribers.title'),
        content: t('firstTimePanel.getSubscribers.subtitle'),
        linkText: t('firstTimePanel.getSubscribers.learnMore'),
        helpId: GET_SUBSCRIBERS_HELP_ID,
        headerBg:
          'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/get-subscribers-first-time.gif',
        headerBgRetina:
          'http://static.parastorage.com/services/wix-form-builder/1.1589.0/assets/gifs/first-time-experience/get-subscribers-first-time-retina.gif',
        bgColor: '#542790',
      }
    }

  return {}
}
